import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { BASE_ZINDEX } from '../popup-factory';
import Loading from './Loading';
import SelectSkuByColor from './SelectSkuByColor';
import { SelectBreakdownSkuPopup, mapDispatchToProps, mapStateToProps } from './SelectBreakdownSkuPopup';
import { createGlobalStyle } from './helpers';
import { Button, Col, LabeledIconInput, Popup, Row, SearchIcon, Theme, themeOptions } from '@commonsku/styles';

export const GlobalStyle = createGlobalStyle();

class SelectBreakdownSkuByColorPopup extends SelectBreakdownSkuPopup {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      quantities: {},
      search_query: '',
    };

    ['handleAdd', 'handleUpdate', 'onSkusSelected'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  async handleAdd(e) {
    e.preventDefault();
    const { skus } = this.state;
    if (skus.length) {
      const product_skus = await this.loadProductSkus(skus);
      product_skus.forEach(product_sku => {
        if (!product_sku.product_sku_id) { return; }
        this.props.onAdd(product_sku.product_sku_id);
      });
      this.props.onClosePopup();
    }
  }

  async handleUpdate(e) {
    e.preventDefault();
    const { skus } = this.state;
    if (skus.length) {
      const product_skus = await this.loadProductSkus(skus);
      if (product_skus.length === 0) { return; }

      const product_sku_id = product_skus[0].product_sku_id;
      this.props.onUpdate(product_sku_id);
      this.props.onClosePopup();
    }
  }

  onSkusSelected(skus, quantities) {
    this.setState({ skus, quantities });
  }

  render() {
    const { product, product_id, onAdd, index, onClosePopup, title = null, message = null, qty } = this.props;
    const { skus } = this.state;
    if (!product) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loading />
        </div>
      );
    }

    return (
      <Theme theme={themeOptions}>
        <GlobalStyle theme={themeOptions} />
        <Popup
          title={!!title ? title : (!!onAdd ? 'Add Breakdown' : 'Select SKU')}
          onClose={onClosePopup}
          zIndex={BASE_ZINDEX + index}
          overlayZIndex={BASE_ZINDEX + index}
          controls={<>
            <LabeledIconInput
              label=''
              Icon={<SearchIcon color='#D0D7DC' style={{ marginTop: '30%', }} />}
              className='sku-options-search-query'
              placeholder="Filter"
              value={this.state.search_query}
              onChange={e => this.setState({ search_query: e.target.value })}
              style={{
                margin: 0,
                marginRight: 10,
                width: 225,
                height: 54,
              }}
              iconColor='#D0D7DC'
              iconLabelStyles={{ height: '100%', background: 'none' }}
              containerStyle={{ display: 'inline-block', }}
            />
            <Button
              variant={'secondary'}
              onClick={onClosePopup}
              style={{ marginRight: 10, }}
            >Cancel</Button>
            <Button
              variant={skus.length === 0 ? 'disabled' : 'cta'}
              disabled={skus.length === 0}
              onClick={!!onAdd ? this.handleAdd : this.handleUpdate}
            >{!!onAdd ? 'Add' : 'Save'}</Button>
          </>}
        >
          <Row>
            <Col xs>
              <SelectSkuByColor
                product_id={product_id}
                product={product}
                currency_id="USD"
                onSkusSelected={this.onSkusSelected}
                value={skus}
                valueQty={qty && skus && skus.length > 0 ? { [skus[0]]: parseInt(qty) } :  {}}
                multi={!!onAdd}
                maxHeight='100%'
                message={!!message ? message : <span>Please select the SKU{!!onAdd && 's'} you wish to {!!onAdd ? 'add' : 'use'}.</span>}
                search_query={this.state.search_query}
              />
            </Col>
          </Row>
        </Popup>
      </Theme>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectBreakdownSkuByColorPopup);
