import React from 'react';
import { connect } from 'react-redux';
import { isArray, isUndefined, map, } from 'lodash';
import { Tabs, } from '@commonsku/styles';

import { SelectSku, mapDispatchToProps, mapStateToProps } from './SelectSku';
import ReactTooltip from './helpers/ReactTooltip';
import { sizeSort } from '../utils';
import ProductOptionsByColor from './select-sku/ProductOptionsByColor';

const defaultSort = (a, b) => a < b ? -1 : (a > b ? 1 : 0);

class SelectSkuByColor extends SelectSku {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      valueQty: props.valueQty || {},
    };
  }

  toggleValue(sku, qty) {
    const { multi = false, selectable = true, onSkusSelected = () => {} } = this.props;
    const { skus, value } = this.state;
    if (!selectable) {
      return;
    }
    let newValue = [];
    if (isUndefined(sku)) {
      if (value.length !== skus.length) {
        newValue = map(skus, 'sku');
      }
    } else if (isArray(sku)) {
      if (!multi) {
        return;
      }
      if (qty > 0) {
        newValue = value.concat(sku);
      }
    } else {
      newValue = multi
        ? (value.includes(sku) ? value.filter(v => v !== sku) : value.concat(sku))
        : [sku]
      ;
    }
    newValue = skus.filter(sku => newValue.includes(sku.sku)).map(sku => sku.options.reduce(
      (t, o) => ({ ...t, [o.option_axis]: o.option_name }),
      { sku: sku.sku }
    )).sort(
      (a, b) => sizeSort(a.size, b.size)
    ).sort(
      (a, b) => defaultSort(a.color, b.color)
    ).map(
      sku => sku.sku
    );

    const valueQty = {
      ...(multi ? this.state.valueQty : {}),
    };
    if (isArray(sku)) {
      sku.forEach(s => { valueQty[s] = qty; });
    } else {
      valueQty[sku] = qty;
    }

    this.setState({ value: newValue, valueQty });
    onSkusSelected(newValue, valueQty);
  }

  render() {
    const { multi = false, } = this.props;
    const { value, skus } = this.state;
    const data = this.getParsedData();

    const onToggleSku = (sku, qty = 1) => {
      if (!this.props.onSkusSelected) { return; }
      if (isArray(sku)) {
        this.toggleValue(sku, qty);
        return;
      }
      if (!multi) {
        if (qty <= 0) { return; }
        this.toggleValue(sku, qty);
        return;
      }
      if (qty < 0) { return; }
      this.toggleValue(sku, qty);
    };

    return (<>
      <ReactTooltip
        id={'select_sku_tooltip'}
        className="select-sku-tooltip"
        html={true}
      />
      <ProductOptionsByColor
        axes={data.axes}
        parts={data.parts}
        skus={skus}
        selectedSkus={!!this.props.onSkusSelected ? value : []}
        skusQty={this.state.valueQty}
        onToggleSku={onToggleSku}
        autoOpen={!this.props.multi}
        message={this.props.message}
        multi={this.props.multi}
      />
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSkuByColor);
